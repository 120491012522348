import React from 'react';
import { useTranslation } from 'react-i18next';

interface CheckboxInsightsFlowProps {
  onChange: any;
  privacyUrl: string;
  checked: boolean;
  lng: string;
}

function CheckboxInsightsFlow({
  onChange,
  checked,
  privacyUrl,
  lng,
}: CheckboxInsightsFlowProps) {
  const { t } = useTranslation('translation', { lng });
  return (
    <div
      className="checkbox-wrapper-insights-flow"
      style={{ display: 'flex' }}
    >
      <input
        type="checkbox"
        defaultChecked={checked}
        onChange={onChange}
      />
      <div className="insightsFlowTextProfileExists">
        {t('ms-teams-insights-flow-colour-energies-exist-confirm-text')}
        {' '}
        <a
          href={privacyUrl}
          target="_blank"
          style={{ textDecoration: 'none', color: '#1673A3' }}
          rel="noreferrer"
        >
          {' '}
          {t('ms-teams-insights-flow-colour-energies-exist-privacy-link')}
        </a>
      </div>
    </div>
  );
}

export default CheckboxInsightsFlow;
