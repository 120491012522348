import React from 'react';
import * as crypto from 'crypto-js';
import { tokens } from '@fluentui/react-theme';
import { Label, makeStyles } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { authentication, app } from '../../services/ms-teams-service';
import { getErrorTextColour } from '../../util/theme';
import TeamsButton from '../common/TeamsButton';

interface ConsentPopupProps {
  consentScope: string;
  successCallback(): unknown;
  theme: string;
}

const useStyles = makeStyles({
  padding: { marginBottom: tokens.spacingVerticalXS },
});

function ConsentPopup({
  consentScope,
  successCallback,
  theme,
}: ConsentPopupProps) {
  const { t } = useTranslation();

  const classes = useStyles();

  const openAdminConsentWindow = async () => {
    await app.initialize();

    // Get the user context in order to extract the tenant ID
    app.getContext().then((context) => {
      const tenant = context?.user?.tenant?.id;
      const clientId = process.env.REACT_APP_MICROSOFT_APP_ID;

      let queryParams: unknown = {
        tenant: `${tenant}`,
        client_id: `${clientId}`,
        response_type: 'token', // token_id in other samples is only needed if using open ID
        scope: consentScope,
        redirect_uri: `${window.location.origin}/auth-end`,
        nonce: crypto.lib.WordArray.random(16),
      };

      // Consent URL needs to have the same domain and the primary page so the popup not get
      // blocked on Desktop. This is redirecting to the aut-start page to load the consent url.
      const url = `${window.location.origin}/auth-start?https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?`;
      queryParams = new URLSearchParams(queryParams as string).toString();
      const authorizeEndpoint = url + queryParams;

      // Redirect to the Azure authorization endpoint.
      // When that flow completes, the user will be directed to auth-end
      // Go to ClosePopup.js
      authentication
        .authenticate({
          url: authorizeEndpoint,
          width: 600,
          height: 535,
        })
        .then(() => {
          successCallback();
        });
    });
  };

  return (
    <div className="d-flex flex-column">
      <Label
        className={classes.padding}
        style={{ color: getErrorTextColour(theme) }}
        size="large"
      >
        {t('ms-teams-consent-popup-title')}
      </Label>
      <Label style={{ color: getErrorTextColour(theme) }}>
        {t('ms-teams-consent-popup-description')}
      </Label>
      <div
        className="pt-2 pb-2"
        style={{
          maxWidth: 160,
          maxHeight: 32,
          margin: 0,
        }}
      >
        <TeamsButton
          theme={theme}
          onClick={openAdminConsentWindow}
          btnText={t('ms-teams-consent-popup-btn-title')}
        />
      </div>
    </div>
  );
}

export default ConsentPopup;
