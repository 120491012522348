import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'react-i18next';
import { app, pages } from '../services/ms-teams-service';
import HeaderImage from '../images/Frame.svg';

/**
 * The contents displayed when the task module dialog is invoked
 * this allows the creation of tabs in Chats, Meetings & Channels.
 */
function EnergyTabConfig() {
  const { t } = useTranslation();
  const [themeValue, setTheme] = useState('');

  const getData = async () => {
    const teamsAppData = await app.getContext();
    const { theme } = teamsAppData.app;
    setTheme(theme);
  };

  useEffect(() => {
    getData();
  }, [themeValue]);

  app.initialize().then(() => {
    /**
     * When the user clicks "Save", save the url for your configured tab.
     * This allows for the addition of query string parameters based on
     * the settings selected by the user.
     */
    pages.config.registerOnSaveHandler((saveEvent) => {
      const baseUrl = `https://${window.location.hostname}:${window.location.port}`;

      pages.config
        .setConfig({
          suggestedDisplayName: 'Insights Discovery',
          entityId: 'Insights',
          contentUrl: `${baseUrl}/chat`,
          websiteUrl: `${baseUrl}/chat`,
        })
        .then(() => {
          hotjar.event('button-tab-added');
          saveEvent.notifySuccess();
        });
    });

    /**
     * After verifying that the settings for your tab are correctly
     * filled in by the user you need to set the state of the dialog
     * to be valid.  This will enable the save button in the configuration
     * dialog.
     */
    pages.config.setValidityState(true);
  });

  if (!themeValue) return <p>Loading...</p>;

  return (
    <div className={`tab_config_container bg_${themeValue}_card`}>
      <img src={HeaderImage} alt="header" />
      <h2 className={`tab_config_title text_colour_${themeValue}`}>
        {t('ms-teams-tab-config-title')}
      </h2>
      <div className={`tab_config_description bg_${themeValue}_card`}>
        <p className={`text_colour_${themeValue}`}>
          {t('ms-teams-tab-config-description-1')}
        </p>
        <p className={`text_colour_${themeValue}`}>
          {t('ms-teams-tab-config-description-2')}
        </p>
      </div>
    </div>
  );
}

export default EnergyTabConfig;
