/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ColourEnergyOrder, ColourEnergyValues } from '../../../global/types';
import EnergySplit from '../EnergySplit';

interface ColourEnergyBreakdownProps {
  displayName: string;
  profileDate: string;
  chapters: Array<string>;
  fullProfile: boolean;
  colourEnergyValues: ColourEnergyValues;
  colourEnergyOrder: ColourEnergyOrder;
  lng: string;
}

/**
 * This is the content displayed within the setup modal. Will show varying content for
 * the two versions of the Insights profile (Full & explore).
 */
function ColourEnergyBreakdown({
  displayName,
  profileDate,
  chapters,
  fullProfile,
  colourEnergyValues,
  colourEnergyOrder,
  lng,
}: ColourEnergyBreakdownProps) {
  const { t } = useTranslation('translation', { lng });
  return (
    <div className="colourEnergyBreakdownCard mt-4 mb-4">
      <h3 data-hj-suppress className="colourEnergyBreakdownTitle">
        {fullProfile
          ? t('ms-teams-energy-block-insights-discovery')
          : t('ms-teams-energy-block-insights-explore')}{' '}
        {displayName} {moment.utc(profileDate).local().format('Do MMM YYYY')}
      </h3>
      {fullProfile ? (
        <p
          className="colourEnergyBreakdownSubtitle"
          style={{ marginBottom: 15 }}
        >
          {' '}
          {t('ms-teams-energy-block-chapters')}
          <span className="colourEnergyBreakdownChapters">
            {' '}
            {chapters.join(', ')}
          </span>
        </p>
      ) : null}
      <EnergySplit
        theme="default" // no dark or contrast support for modal
        colourEnergyValues={colourEnergyValues}
        colourEnergyOrder={colourEnergyOrder}
        fullProfile={fullProfile}
        lng={lng}
      />
    </div>
  );
}

export default ColourEnergyBreakdown;
