import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import DoHeading from '../common/Headings/Do';
import DontHeading from '../common/Headings/Dont';
import Statement from '../common/Statement';
import { getTextLabelColour } from '../../util/theme';
import normalizeArrayToObject from '../../util/normalizeArrayToObject';

interface CommunicationDosDontsTileProps {
  people: any;
  usersAndLeadEnergy: any;
  theme: string;
  colourEnergyUserCount: number;
  totalUsers: number;
  communicationStatements: any;
}

let dos: Array<any>;
let donts: Array<any>;

function CommunicationDosDontsTile({
  people,
  usersAndLeadEnergy,
  theme,
  colourEnergyUserCount,
  totalUsers,
  communicationStatements,
}: CommunicationDosDontsTileProps) {
  const { t } = useTranslation();
  const [commsDos, setCommsDos] = useState<any>(null);
  const [commsDonts, setCommsDonts] = useState<any>(null);

  const normalizePeople = normalizeArrayToObject(people, 'displayName');
  const handleProfileText = (profiles: any) => (profiles.length > 2
    ? `${t('ms-teams-comms-do-dont-in-profiles')} ${profiles
      .slice(0, 2)
      .map(({ displayName }: any) => displayName.split(' ')[0])
      .join(', ')}...`
    : profiles.length > 1
      ? `${t('ms-teams-comms-do-dont-in-profiles')} ${profiles
        .map(({ displayName }: any) => displayName.split(' ')[0])
        .join(', ')}`
      : `${profiles[0].displayName}${t('ms-teams-comms-do-dont-leads-with')} ${
        usersAndLeadEnergy[profiles[0].displayName]
      }`);

  const getRandom = (arr: Array<any>, n: number) => {
    let data = arr;
    if (!arr) return [];

    if (!Array.isArray(data)) data = [data];

    const result = _.shuffle(data).slice(0, n);
    return result;
  };

  useEffect(() => {
    dos = communicationStatements.communicationDos.map((item: any) => {
      const len = item.profiles.length;
      const profilePhotos = item.profiles
        .slice(0, len > 2 ? 2 : len)
        .map((user: any) => ({
          image:
            normalizePeople
            && normalizePeople.byId
            && normalizePeople.byId[user.displayName as string]
              ? normalizePeople.byId[user.displayName].profilePhoto
              : null,
          displayName: user.displayName,
        }));
      return (
        <Statement
          statement={item.statement.text}
          images={profilePhotos}
          profilesText={handleProfileText(item.profiles)}
          threePlusProfiles={len > 2}
        />
      );
    });

    donts = communicationStatements.communicationDonts.map((item: any) => {
      const len = item.profiles.length;
      const profilePhotos = item.profiles
        .slice(0, len > 2 ? 2 : len)
        .map((user: any) => ({
          image:
            normalizePeople
            && normalizePeople.byId
            && normalizePeople.byId[user.displayName]
              ? normalizePeople.byId[user.displayName].profilePhoto
              : null,
          displayName: user.displayName,
        }));
      return (
        <Statement
          statement={item.statement.text}
          images={profilePhotos}
          profilesText={handleProfileText(item.profiles)}
          threePlusProfiles={len > 2}
        />
      );
    });

    setCommsDos((dos || []).slice(0, 3));
    setCommsDonts((donts || []).slice(0, 3));
  }, [people]);

  const showAnotherBatch = () => {
    setCommsDos(getRandom(dos, 3));
    setCommsDonts(getRandom(donts, 3));
  };

  const commsDosSize = commsDos ? commsDos.length : 0;
  const commsDontsSize = commsDonts ? commsDonts.length : 0;
  const totalStatementsToShow = commsDosSize + commsDontsSize;

  const getPeopleSharingStatements = () => {
    let counter = 0;
    for (let i = 0; i < people.length; i += 1) {
      const person = people[i];

      if (person.communicationDos) {
        const sharedDosCount = (person.communicationDos || []).filter(
          (item: any) => item.public,
        );

        if (sharedDosCount.length >= 1) {
          counter += 1;
        }
      } else if (person.communicationDonts) {
        const sharedDontsCount = (person.communicationDonts || []).filter(
          (item: any) => item.public,
        );

        if (sharedDontsCount.length >= 1) {
          counter += 1;
        }
      }
    }

    return counter;
  };

  const peopleSharingStatementsCounter = getPeopleSharingStatements();

  return (
    <div
      className={`comms_do_dont_${theme}_card border_${theme} p-4 d-flex flex-column my-1 my-md-3 col-12 col-md-6 col-xl-6 ms-md-2 ms-0 mt-3 mt-md-0 communication_do_dont_container`}
      style={{ flex: 1, height: 'auto' }}
    >
      <h3
        className={`text_colour_${theme} section_title`}
        style={{ margin: 0, marginBottom: 2 }}
      >
        {`${t('ms-teams-comms-do-dont-tile-title')}`}
      </h3>
      <p className={`text_colour_${theme} default_sub_title mb-3`}>
        {t('ms-teams-comms-do-dont-tile-subtitle-1')}
        <span style={{ fontWeight: 700 }}>
          {` ${peopleSharingStatementsCounter} ${t(
            'ms-teams-communication-card-out-of',
          )} ${totalUsers} `}
        </span>
        {t('ms-teams-comms-do-dont-tile-subtitle-2', {
          count: commsDosSize + commsDontsSize,
        })}
      </p>
      {colourEnergyUserCount === 0 ? (
        <p>{t('ms-teams-comms-do-dont-no-users-energies')}</p>
      ) : (
        <>
          <div className="d-flex flex-column flex-lg-row comms_list_container">
            <div className="d-flex flex-column col-12 col-lg-6">
              <DoHeading theme={theme} />
              {commsDos && commsDos.length > 0 ? (
                commsDos
              ) : (
                <p className={`text_colour_${theme} pt-2`}>
                  {t('ms-teams-comms-do-no-users-energies')}
                </p>
              )}
            </div>
            <div className="d-flex flex-column col-12 col-lg-6">
              <DontHeading theme={theme} />
              {commsDonts && commsDonts.length > 0 ? (
                commsDonts
              ) : (
                <p className={`text_colour_${theme} pt-2`}>
                  {t('ms-teams-comms-dont-no-users-energies')}
                </p>
              )}
            </div>
          </div>
          {(donts && donts.length > 3) || (dos && dos.length > 3) ? (
            <button
              type="button"
              onClick={showAnotherBatch}
              className="personal_dashboard_button mt-2"
              style={{ color: getTextLabelColour(theme) }}
            >
              {`${t(
                'ms-teams-comms-do-dont-show',
              )} ${totalStatementsToShow} ${t('ms-teams-comms-do-dont-more')}`}
            </button>
          ) : null}
        </>
      )}
    </div>
  );
}

export default CommunicationDosDontsTile;
